import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

// ROUTING
import { BrowserRouter } from 'react-router-dom'
import App from 'Containers/App'

// REDUX
import { Provider } from 'react-redux'
import store from 'Store'

// TRANSLATIONS
import { IntlProvider } from 'react-intl'
import translations_it from './translations/it.json'
import translations_en from './translations/en.json'

import * as serviceWorker from './serviceWorker'

const translations = {
  it: translations_it,
  en: translations_en,
}
const language = navigator.language.split(/[-_]/)[0] // language without region code

const locale = translations[language] ? language : 'it'
const messages = translations[language] || translations_it

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <IntlProvider locale={locale} messages={messages}>
        <App />
      </IntlProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
