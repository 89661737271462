const env = process.env.REACT_APP_ENV
const envConfig = {
  dev: {
    API_URL: 'https://gestionecode-api-test.cloud.leroymerlin.it/api/v1',
    INTERNAL_IP_API_URL: 'https://gip.leroymerlin.it',
    MAGICINFO_TRANSCODE_API_URL: 'https://gtdp-uat1.nprd.api.devportal.adeo.cloud/api-queue-management-magicinfo-transcode/v1',
    MAGICINFO_TRANSCODE_API_ENDPOINT: '/test-queuemanagement--function-magicinfo-transcode',
    MAGICINFO_TRANSCODE_API_KEY: 'twwNmz0UxwQwCg0Y3WepZ9Ptuuyrs4hY',
    FE_CUSTOMER_URL: 'https://lmit-queuemanagement-test.web.app',
  },
  test: {
    API_URL: 'https://gestionecode-api-test.cloud.leroymerlin.it/api/v1',
    INTERNAL_IP_API_URL: 'https://gip.leroymerlin.it',
    MAGICINFO_TRANSCODE_API_URL: 'https://gtdp-uat1.nprd.api.devportal.adeo.cloud/api-queue-management-magicinfo-transcode/v1',
    MAGICINFO_TRANSCODE_API_ENDPOINT: '/test-queuemanagement--function-magicinfo-transcode',
    MAGICINFO_TRANSCODE_API_KEY: 'twwNmz0UxwQwCg0Y3WepZ9Ptuuyrs4hY',
    FE_CUSTOMER_URL: 'https://lmit-queuemanagement-test.web.app',
  },
  prod: {
    API_URL: 'https://gestionecode-api.cloud.leroymerlin.it/api/v1',
    INTERNAL_IP_API_URL: 'https://gip.leroymerlin.it',
    MAGICINFO_TRANSCODE_API_URL: 'https://gtdp.api.devportal.adeo.cloud/api-queue-management-magicinfo-transcode/v1',
    MAGICINFO_TRANSCODE_API_ENDPOINT: '/prod-queuemanagement--function-magicinfo-transcode',
    MAGICINFO_TRANSCODE_API_KEY: 'wSogVfbhBlfqBP6oX1v6WeFkJArFDPne',
    FE_CUSTOMER_URL: 'https://code.leroymerlin.it',
  },
}

export default envConfig[env]
