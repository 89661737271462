import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Badge } from 'antd'

import { StyledFooter } from './styled'

import { colors } from 'Common/constants'

const Footer = props => {
  const { intl, status, lastUpdate, dispenserId } = props

  const statusBadgeColor = {
    OK: colors.success,
    KO: colors.error
  }

  return (
    <StyledFooter>
      <small>v{process.env.REACT_APP_VERSION}</small>
      {dispenserId ? <small>{`Dispenser ${dispenserId}`}</small> : null}
      <small>{process.env.REACT_APP_ENV}</small>
      <Badge
        status={'processing'}
        color={statusBadgeColor[status]}
        text={intl.formatMessage({ id: `footer.status.${status}` })}
      />
      <small><FormattedMessage id={'footer.lastUpdate'} values={{ lastUpdate }} /></small>
    </StyledFooter>
  )
}
export default injectIntl(Footer)
